import React, { useEffect, useState } from "react";
import {
    makeStyles,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    TextField,
    Button,
    Typography,
    CircularProgress
} from "@material-ui/core";
import AddSpoc from './icons/addSPOC.png';
import ManageSpocTable from "../../components/manageSpocTable/manageSpocTable";
import Header from "../../components/headerAdmin/Header";
import './manageSpoc.css';
import { addSpoc } from "../../apis/organisationAdminAPI/manageSpoc";
import { updateSpoc } from "../../apis/organisationAdminAPI/manageSpoc";
import { getSPOCByOrg } from "../../apis/organisationAdminAPI/manageSpoc";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import { Autocomplete } from "@material-ui/lab";
import { getClientByOrg } from "../../apis/organisationAdminAPI/manageClient";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router";
import BasicHeader from '../../components/basicHeader/BasicHeader.js';

const ManageSpoc = (props) => {
    const userRecruitmentpartnerId = props?.location?.state?.recruitmentPartnerId ? props?.location?.state?.recruitmentPartnerId : props?.userRecruitmentpartnerId;
    const userType = props?.location?.state?.userType ? props?.location?.state?.userType : props?.userType;
    const idContextTest = props?.location?.state?.id ? props?.location?.state?.id : props?.id;
    const userRecords = props?.location?.state?.userRecords ? props?.location?.state?.userRecords : props?.userRecords;
    const userRole = props?.location?.state?.userRole ? props?.location?.state?.userRole : props?.userType;
    const history = useHistory();
    const [openDialog, setOpenDialog] = useState(false);
    const [editedRow, setEditedRow] = useState(null);
    const [spocs, setSpocs] = useState([]);
    const [isloading, setIsloading] = useState(false);
    const [clientData, setClientData] = useState([]);
    const [activeDropDown, setActiveDropDown] = useState([
        { name: "True", value: true },
        { name: "False", value: false },
    ]);
    const [spocInfo, setSpocInfo] = useState({
        name: "",
        client: "",
        email: "",
        address: "",
        active: { name: "True", value: true }
    })
    const [isUserDataUpdating, setIsUserDataUpdating] = useState(false)

    const handleOpenDialog = () => {
        setOpenDialog(true);
        setEditedRow(null);
    };
    const handelEditOpenDialog = () => {
        setOpenDialog(true);
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setEditedRow(null);
        setSpocInfo({
            name: "",
            client: "",
            email: "",
            address: "",
            active: { name: "True", value: true }
        })
    };

    const handleSubmit = async () => {
        if (editedRow) {
            let payload = {}
            if (spocInfo.name) {
                payload.spocName = spocInfo.name;
            }
            if (spocInfo.client) {
                payload.clientId = spocInfo.client;
            }
            if (spocInfo.email) {
                payload.spocEmail = spocInfo.email;
            }
            if (spocInfo.address) {
                payload.address = spocInfo.address;
            }
            if (spocInfo.active) {
                payload.isActive = spocInfo.active.value;
            }
            try {
                setIsUserDataUpdating(true)
                const response = await updateSpoc(editedRow?._id, payload);
                getSpocs();
                setIsUserDataUpdating(false)
                if (response.status === 200) {
                    NotificationManager.success("POC details updated successfully", "Success", 2500);
                    handleCloseDialog();
                } else if (response.status === 202) {
                    const errorMessage = response.data.message;
                    NotificationManager.error(errorMessage, "Error", 2500);
                }
            } catch (error) {
                console.log(error)
                if(error.response.status === 400 && error.response.data.errorCode === 400){
                    const errorMessage = error.response.data.errMessage;
                    NotificationManager.error(errorMessage, "Error", 2500);
                }
            }
        }
        else {
            if (spocInfo.name === "" ||
                spocInfo.client === "" ||
                spocInfo.email === "" ||
                spocInfo.address === "" ||
                spocInfo.active === undefined
            ) {
                return NotificationManager.error(
                    "Please fill in all the required fields",
                    "Error",
                    2500
                );
            } else {
                let payload = {
                    "spocName": spocInfo.name,
                    "clientId": spocInfo.client,
                    "spocEmail": spocInfo.email,
                    "address": spocInfo.address,
                    "isActive": spocInfo.active.value,
                    "recruitmentpartnerId": userRecruitmentpartnerId
                }
                
                try {
                    setIsUserDataUpdating(true)
                    const response = await addSpoc(payload);
                    getSpocs();
                    setIsUserDataUpdating(false)
                    if (response.status === 200) {
                        NotificationManager.success("POC has been added successfully", "Success", 2500);
                        handleCloseDialog();
                    } else if (response.status === 202) {
                        const errorMessage = response.data.message;
                        NotificationManager.error(errorMessage, "Error", 2500);
                    }else if (response.status === 400 && response.errorCode === 400) {
                        const errorMessage = response.data.message;
                        NotificationManager.error(errorMessage, "Error", 2500);
                    }
                } catch (error) {
                    console.log(error)
                    if(error.response.status === 400 && error.response.data.errorCode === 400){
                        const errorMessage = error.response.data.errMessage;
                        NotificationManager.error(errorMessage, "Error", 2500);
                    }
                }
            }
        }
    };

    const getSpocs = async () => {
        let payload = {
            recruitmentPartnerId: userRecruitmentpartnerId
        }
        try {
            const response = await getSPOCByOrg(payload);
            if (response.status === 200) {
                setSpocs(response.data.data)
            }
        } catch (error) {
            console.log(error);
        }
    }
    const allClientsData = async () => {
        let payload = {
            recruitmentPartnerId: userRecruitmentpartnerId
        }
        try {
            setIsloading(false)
            let response = await getClientByOrg(payload);
            if (response.status === 200) {
                setClientData(response.data.data);
            }
            setIsloading(true)

        } catch (error) {
            setIsloading(false)
            setClientData([]);
            console.log(error);
        }
    };
    const handleEdit = (rowData) => {
        setSpocInfo({
            ...spocInfo,
            name: rowData.spocName,
            client: rowData.client[0],
            email: rowData.spocEmail,
            address: rowData.address,
            active: {
                name: rowData.isActive ? "True" : "False",
                value: rowData.isActive
            }
        })
        setEditedRow(rowData); // Set the row data when edit button is clicked
        handelEditOpenDialog(); // Open the dialog
    };
    useEffect(() => {
        getSpocs()
        allClientsData()
    }, [])

    const handleNameChange = (e) => {
        if (e.target.value) {
            setSpocInfo({ ...spocInfo, name: e.target.value })
        } else {
            setSpocInfo({ ...spocInfo, name: "" })
        }
    }
    const handleClientChange = (e, value) => {
        if (value) {
            setSpocInfo({ ...spocInfo, client:value })
        } else {
            setSpocInfo({ ...spocInfo, client: "" })
        }
    }
    const handleEmailChange = (e) => {
        if (e.target.value) {
            setSpocInfo({ ...spocInfo, email: e.target.value })
        } else {
            setSpocInfo({ ...spocInfo, email: "" })
        }
    }
    const handleAddressChange = (e) => {
        if (e.target.value) {
            setSpocInfo({ ...spocInfo, address: e.target.value })
        } else {
            setSpocInfo({ ...spocInfo, address: "" })
        }
    }
    const handleActiveChange = (e, value) => {
        if (value) {
            setSpocInfo({ ...spocInfo, active: value })
        } else {
            setSpocInfo({ ...spocInfo, active: "" })
        }
    }
    const goToDashboard = () => {
        history.push({
            pathname: "/dashboard",
            state: {
                userType: userType,
                recruitmentPartnerId: userRecruitmentpartnerId,
                id: idContextTest,
                userRecords: userRecords,
                roleName: userRole
            }
        });
    }
    return (
        <>
            {(userType === "Organization Admin") ?
                <>
                    <NotificationContainer />
                    <Header />
                    <div className="section-wrapper">
                        <div className="container w1200 flex-to-footer">
                            <div className="height_adjust mt-110 manage-spoc-main">
                                <div className="header-spoc">
                                    <div className="header-image">
                                        <Typography className="manage-spoc-main-head">Manage POC</Typography>
                                    </div>
                                    {userType === "Admin" ? "" :
                                        <Button className="add-button"
                                            onClick={handleOpenDialog}
                                            startIcon={<img src={AddSpoc} alt="Add SPOC" style={{ width: "16px", height: "16px" }} />}
                                        >
                                            Add New POC
                                        </Button>
                                    }
                                </div>
                                <div className="goBackToRecruiterDashboard mt_10 " onClick={(e) => goToDashboard()}>
                                    <ArrowBackIcon />  &nbsp;Go Back to Dashboard
                                </div>
                                <ManageSpocTable spocs={spocs} isloading={isloading} onEdit={handleEdit} userType={userType} />

                            </div>
                        </div>
                    </div>

                </>
                : <>
                    <div className='Unauthorized_container'>
                        <BasicHeader />
                        <div className="row padding_80 text-center">
                            <div className='UnAuthorized_contain'>
                                <p className="fs-semi-bold fs-40 f-color authorize">
                                    You Are Not Authorized To Visit This Page
                                </p>
                            </div>
                        </div>
                    </div>
                </>
            }

            <Dialog
                size="xs"
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="dialog-title"
                maxWidth="xs"
            >
                <DialogTitle id="dialog-title" className="dialog-title">
                    <img src={AddSpoc} alt="Add SPOC" className="dialog-image" />
                    <span className="dialog-title-text">{editedRow ? 'Edit POC' : 'Add New POC'}</span>
                </DialogTitle>
                <DialogContent className="dialog-content">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">POC Name*</Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                placeholder="Enter POC Name"
                                value={spocInfo.name}
                                onChange={handleNameChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">Client*</Typography>
                            <Autocomplete
                                id="combo-box-demo"
                                value={spocInfo.client}
                                options={clientData}
                                getOptionLabel={(option) => option?.clientName}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder="Client"
                                    />
                                )}
                                onChange={handleClientChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">Email*</Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                placeholder="Enter Email"
                                value={spocInfo.email}
                                onChange={handleEmailChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">Address*</Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                placeholder="Enter Address"
                                value={spocInfo.address}
                                multiline
                                minRows={3}
                                onChange={handleAddressChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">Is Active*</Typography>
                            <Autocomplete
                                id="combo-box-demo"
                                value={spocInfo.active}
                                options={activeDropDown}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder="Select Active"
                                    />
                                )}
                                onChange={handleActiveChange}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <div className="dialog-button">
                        <button
                            className={isUserDataUpdating ? 'updateButton_OrgAdmin_disabled' : 'updateButton_OrgAdmin'}
                            onClick={handleSubmit}
                            disabled={isUserDataUpdating}
                        >
    
                            <span>Save</span>
                            {isUserDataUpdating ? (<CircularProgress className="circularProgress_updateButton"/>) : null}
                        </button>
                        <button onClick={handleCloseDialog} className="dialog-cancel-button">
                            Cancel
                        </button>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ManageSpoc;