import React, { useEffect, useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    TextField,
    Button,
    Typography,
    makeStyles,
    CircularProgress,
} from "@material-ui/core";
import addSPOC from './icons/addSPOC.png';
import './manageClient.css';
import ManageClientTable from '../../components/manageClientTable/manageClientTable'
import Header from "../../components/headerAdmin/Header";
import { addClient, updateClient, getClientByOrg } from "../../apis/organisationAdminAPI/manageClient";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import { Autocomplete } from "@material-ui/lab";
import checkRequisitionExists from "../../apis/checkRequisitionExists";
import BasicHeader from '../../components/basicHeader/BasicHeader.js';
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import getAllDivionByOrg from '../../apis/getAllDivionByOrg/getAllDivisionByOrg';

const useStyles = makeStyles((theme) => ({
    iconButton: {
        position: "absolute",
        top: "37%",
        right: "6px"
    },
    errorMsg: { "marginLeft": "5px", color: " #F32013" },
    addIconButton: { marginTop: "5px" }
}
));

const ManageClient = (props) => {
    const userType = props?.location?.state?.userType ? props?.location?.state?.userType : props?.userType;
    const userRecruitmentpartnerId = props?.location?.state?.recruitmentPartnerId ? props?.location?.state?.recruitmentPartnerId : props?.userRecruitmentpartnerId;
    const idContextTest = props?.location?.state?.id ? props?.location?.state?.id : props?.id;
    const userRecords = props?.location?.state?.userRecords ? props?.location?.state?.userRecords : props?.userRecords;
    const userRole = props?.location?.state?.userRole ? props?.location?.state?.userRole : props?.userType;
    const history = useHistory();
    const [openDialog, setOpenDialog] = useState(false);
    const [clientId, setClientId] = useState();
    const [clientData, setClientData] = useState([]);
    const [isDataLoaded, setisDataLoaded] = useState(false);
    const [editedRow, setEditedRow] = useState(null);
    const [activeDropDown, setActiveDropDown] = useState([
        { name: "True", value: true },
        { name: "False", value: false },
    ]);
    const classes = useStyles();
    const [isActiveFieldDisabled, setIsActiveFieldDisabled] = useState(true)
    const [divisionLink, setDivisionLink] = useState([{ "divisionName": "", "recruitmentLeader": "" }]);
    const [divisionName, setDivisionName] = useState([])
    const [allRecruiterLeader, setallRecruiterLeader] = useState([]);
    const [isUserDataUpdating, setIsUserDataUpdating] = useState(false)
    const [exisitingRequisition, setExisitingRequisition] = useState([])
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };
    const [ClientInfo, setClientInfo] = useState({
        name: "",
        address: "",
        active: { name: "True", value: true }
    })

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setEditedRow(null);
        setClientInfo({
            name: "",
            address: "",
            active: { name: "True", value: true }
        })
        setIsActiveFieldDisabled(true);
        setDivisionLink([{
            "divisionName": "",
            "recruitmentLeader": ""
        }])
        setExisitingRequisition([])
    };
    const handleSubmit = async () => {
        if (editedRow) {
            let temp = 0
            divisionLink.forEach((item) => {
                if (item.divisionName === "" || item.recruitmentLeader === "") {
                    temp = 1
                    NotificationManager.error("Please fill in all the fields", "Error", 2000);
                    return
                }

            })
            if (temp === 0) {
                if (ClientInfo.name === "" || ClientInfo.active === null) {
                    NotificationManager.error("Please fill in all the fields", "Error", 2000);
                } else {


                    let divisionDataUpdate = divisionLink.map(item => ({

                        divisionId: item?.divisionName?._id,
                        recruitmentLeaderDetails: [{ id: item?.recruitmentLeader?.id, email: item?.recruitmentLeader?.email }]
                    }))

                    let payload1 = {
                        "clientName": ClientInfo.name,
                        "address": ClientInfo.address,
                        "isActive": ClientInfo.active.value,
                        "recruitmentpartnerId": userRecruitmentpartnerId,
                        "divisionDetails": divisionDataUpdate
                    }
                    try {
                        setIsUserDataUpdating(true)
                        const response = await updateClient(editedRow._id, payload1);
                        allClientsData();
                        setIsUserDataUpdating(false)
                        if (response.status === 200) {
                            NotificationManager.success("Client details updated successfully", "Success", 2500);
                            handleCloseDialog();
                        } else if (response.status === 202) {
                            const errorMessage = response.data.message;
                            NotificationManager.error(errorMessage, "Error", 2500);
                        }
                    } catch (error) {
                        console.log(error)
                        if(error.response.status === 400 && error.response.data.errorCode === 400){
                            const errorMessage = error.response.data.errMessage;
                            NotificationManager.error(errorMessage, "Error", 2500);
                        }
                    }

                }
            }
        }
        else {
            let temp = 0
            divisionLink.forEach((item) => {

                if (item.divisionName === "" || item.recruitmentLeader === "") {
                    temp = 1
                    NotificationManager.error("Please fill in all the fields", "Error", 2000);
                    return
                }

            })
            if (temp === 0) {


                if (ClientInfo.name === "" || ClientInfo.active === null) {
                    NotificationManager.error("Please fill in all the fields", "Error", 2000);
                }
                else {

                    let divisionData = divisionLink.map(item => ({

                        divisionId: item?.divisionName?._id,
                        recruitmentLeaderDetails: [{ id: item?.recruitmentLeader?.id, email: item?.recruitmentLeader?.email }]
                    }))


                    let payload = {
                        "clientName": ClientInfo.name,
                        "address": ClientInfo.address,
                        "isActive": ClientInfo.active.value,
                        "recruitmentpartnerId": userRecruitmentpartnerId,
                        "divisionDetails": divisionData
                    }
                    try {
                        setIsUserDataUpdating(true)
                        const response = await addClient(payload);
                        allClientsData();
                        setIsUserDataUpdating(false)
                        if (response.status === 200) {
                            NotificationManager.success("Client has been added successfully", "Success", 2500);
                            handleCloseDialog();
                        } else if (response.status === 202) {
                            const errorMessage = response.data.message;
                            NotificationManager.error(errorMessage, "Error", 2500);
                        }
                    } catch (error) {
                        console.log(error)
                        if(error.response.status === 400 && error.response.data.errorCode === 400){
                            const errorMessage = error.response.data.errMessage;
                            NotificationManager.error(errorMessage, "Error", 2500);
                        }
                    }
                }
            }
        }
    };

    const allClientsData = async () => {
        let payload = {
            recruitmentPartnerId: userRecruitmentpartnerId
        }
        try {
            setisDataLoaded(false)
            let response = await getClientByOrg(payload);

            if (response.status === 200) {
                let arr = []
                let data = response.data.data

                for (let i = 0; i < data.length; i++) {
                    let emails = data[i].divisionDetails?.map(division =>
                        division.recruitmentLeaderDetails?.map(leader => leader.email) || []
                    ).flat().join(', ');
                    data[i].recruiterLeaderEmails = emails


                    const divisionNames = data[i].assignedDivisions.flat().map(division => division.divisionName).join(', ');
                    data[i].selectedDivisionNames = divisionNames
                    arr.push(data[i])
                }

                setClientData(arr);
            }
            setisDataLoaded(true)

        } catch (error) {
            setisDataLoaded(false)
            setClientData([]);
            console.log(error);
        }
    };

    const getAllDivision = async () => {
        try {
            let payload = {
                isManageClient: true
            }
            let response = await getAllDivionByOrg(userRecruitmentpartnerId, payload);
            setDivisionName(response.data.data)
        } catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {
        allClientsData()
    }, [])
    const handleNameChange = (e) => {
        if (e.target.value) {
            setClientInfo({ ...ClientInfo, name: e.target.value })
        } else {
            setClientInfo({ ...ClientInfo, name: "" })
        }
    }
    const handleAddressChange = (e) => {
        setClientInfo({ ...ClientInfo, address: e.target.value })
    }
    const handleActiveChange = async (e, value) => {
        if (value) {
            setClientInfo({ ...ClientInfo, active: value })
        } else {
            setClientInfo({ ...ClientInfo, active: null })
        }

    }
    const handelEditOpenDialog = () => {
        setOpenDialog(true);
    }


    const divisionChangeHandler = (value, index) => {
        setallRecruiterLeader([])
        const originalData = [...divisionLink];
        const changingData = originalData[index];
        if (value) {
            changingData["divisionName"] = value;
            changingData["recruitmentLeader"] = "";
        } else {
            changingData["divisionName"] = "";
            changingData["recruitmentLeader"] = "";
        }
        originalData[index] = changingData;
        setDivisionLink(originalData || []);
    }

    const recruitmentLeaderChangeHandler = (value, index) => {

        const originalData = [...divisionLink];
        const changingData = originalData[index];
        if (value) {
            changingData["recruitmentLeader"] = value;
        } else {
            changingData["recruitmentLeader"] = "";
        }
        originalData[index] = changingData;
        setDivisionLink(originalData || []);

    }


    const handleEdit = async (rowData) => {
        setClientInfo({
            ...ClientInfo,
            name: rowData.clientName,
            address: rowData.address,
            active: {
                name: rowData.isActive ? "True" : "False", 
                value: rowData.isActive 
            }
        });
        setClientId(rowData._id);
        setEditedRow(rowData); // Set the row data when edit button is clicked
        handelEditOpenDialog(); // Open the dialog
        handelEditOpenDialog(); // Open the dialog  
        const transformedDivisionLink = rowData.divisionDetails.map((detail, index) => ({
            divisionName: divisionName.filter((item) => item._id === detail?.divisionId)[0],
            recruitmentLeader: detail?.recruitmentLeaderDetails?.[0]
        }));
        setDivisionLink(transformedDivisionLink);
        const dataRes = rowData ? await checkRequisitionExists(rowData?._id, 'Client') : null;
        setExisitingRequisition(dataRes?.data?.data)
        const requisitionExists = dataRes?.data?.data?.length;
        if (requisitionExists > 0 && rowData) {
            setIsActiveFieldDisabled(true)
        }
        else {
            setIsActiveFieldDisabled(false)
        }
    };
    const goToDashboard = () => {
        history.push({
            pathname: "/dashboard",
            state: {
                userType: userType,
                recruitmentPartnerId: userRecruitmentpartnerId,
                id: idContextTest,
                userRecords: userRecords,
                roleName: userRole
            }
        });
    }



    const addDiVisionLink = () => {
        const row = {
            "divisionName": "",
            "recruitmentLeader": ""
        };
        if (divisionLink) {
            setDivisionLink([...divisionLink, row])
        }

    }

    const extraDivisionLinkClose = (index,item) => {
        if(checkDisbale_division(item) || checkDisbale_RecruiterLeader(item)){
            NotificationManager.error("Unable to make changes as a requisition already exists for this client and division.", "Error", 2500);
        }else{
            let originalData = [...divisionLink];
        originalData.splice(index, 1);
        setDivisionLink(originalData || []);
        }
        
    }


    const checkDisbale_division = (item) => {
        let data = exisitingRequisition?.filter((element) => element.divisionId === item.divisionName?._id)
        if (data?.length > 0) {
            return true
        }
        else {
            return false;
        }
    }
    const checkDisbale_RecruiterLeader = (item) => {
        let data = exisitingRequisition?.filter((element) => element.assignedTo === item.recruitmentLeader?.id)
        if (data?.length > 0) {
            return true
        }
        else {
            return false;
        }
    }

    const filteredRecruitmentLeaders = (divisionId) => {
        let data = divisionName.filter((item) => item._id === divisionId)
        data = data.map((item) => item.recruitmentLeaderData)
        return data
    }

    const getFilteredDivision = () => {
        let arr = [];

        divisionName.forEach((item) => {
            let data = divisionLink.filter((element) => element.divisionName?._id === item._id)
            if (data.length === 0) {
                arr.push(item)
            }
        })
        return arr
    }

    useEffect(() => {
        getAllDivision();
    }, [])




    return (
        <>
            {(userType === "Organization Admin") ?
                <>
                    <NotificationContainer />
                    <Header />
                    <div className="section-wrapper">
                        <div className="container w1200 flex-to-footer">
                            <div className="height_adjust mt-110 manage-client-main">
                                <div className="header-client-manager">
                                    <div className="header-image-manage-client">
                                        <Typography className="manage-client-main-head">Manage Clients</Typography>
                                    </div>
                                    {userType === "Admin" ? "" :
                                        <Button className="add-button-manageclient"
                                            onClick={handleOpenDialog}
                                            startIcon={<img src={addSPOC} alt="Add Client" style={{ width: "16px", height: "16px" }} />}
                                        >
                                            Add New Client
                                        </Button>
                                    }
                                </div>
                                <div className="goBackToRecruiterDashboard mt_10 " onClick={(e) => goToDashboard()}>
                                    <ArrowBackIcon />  &nbsp;Go Back to Dashboard
                                </div>
                                <ManageClientTable clientData={clientData} isDataLoaded={isDataLoaded} onEdit={handleEdit} userType={userType} />
                            </div>
                        </div>
                    </div>
                </>
                :
                <>
                    <div className='Unauthorized_container'>
                        <BasicHeader />
                        <div className="row padding_80 text-center">
                            <div className='UnAuthorized_contain'>
                                <p className="fs-semi-bold fs-40 f-color authorize">
                                    You Are Not Authorized To Visit This Page
                                </p>
                            </div>
                        </div>
                    </div>
                </>
            }

            <Dialog
                // size="xs"
                open={openDialog}
                className="manage-client-modal"
                onClose={handleCloseDialog}
                aria-labelledby="dialog-title"
                maxWidth="sm"
            >
                <DialogTitle id="dialog-title" className="dialog-title-manage-client">
                    <img src={addSPOC} alt="Add SPOC" className="dialog-image" />
                    <span className="dialog-title-text">{editedRow ? 'Edit Client' : 'Add New Client'}</span>
                </DialogTitle>
                <DialogContent className="dialog-content">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">Client Name*</Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                placeholder="Enter Client Name"
                                value={ClientInfo.name}
                                onChange={handleNameChange}
                            />
                        </Grid>
                        <Grid container spacing={1} className="gridContainer_heading_manageClient">
                            <Grid item xs={6}>
                                <Typography variant="body1" component="h2">
                                    Division Name*
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body1" component="h2">
                                    Recruitment Leader*
                                </Typography>
                            </Grid>
                        </Grid>
                        {divisionLink.map((item, i) => {
                            return (
                                <div key={i} className={i !== null && divisionLink?.length > 1 ? "manangeclientContainerDivisionLink" : "manangeclientContainerDivisionLinks"}>
                                    <Grid container spacing={1} className="gridContainer_heading_manageClient">
                                        <Grid item xs={6}>
                                            <Autocomplete
                                                id="multiple-limit-tags"
                                                options={getFilteredDivision()}
                                                getOptionLabel={(option) =>
                                                    option.divisionName ? option.divisionName : ""
                                                }
                                                closeIcon={null}
                                                value={item.divisionName}
                                                disabled={checkDisbale_division(item)}
                                                onChange={(e, val) => divisionChangeHandler(val, i)}
                                                filterSelectedOptions
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        required={true}
                                                        placeholder="Division"
                                                    />
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={6} className="gridContainerRecruitmentLeader">
                                            <div className="recruitmentLeader_contains_client">
                                                <Autocomplete
                                                    id="multiple-limit-tags"
                                                    options={item.divisionName === "" ? [] : filteredRecruitmentLeaders(item?.divisionName?._id)}
                                                    getOptionLabel={(option) =>
                                                        option.email ? option.email : ""
                                                    }
                                                    autoHighlight
                                                    disabled={checkDisbale_RecruiterLeader(item)}
                                                    closeIcon={null}
                                                    value={item.recruitmentLeader}
                                                    onChange={(e, val) => recruitmentLeaderChangeHandler(val, i)}
                                                    renderInput={(params) =>
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            required={true}
                                                            placeholder="Recruitment Leader"
                                                        />
                                                    }
                                                />
                                            </div>
                                            <div className="closeContainer_client">
                                                {(i !== null && divisionLink?.length > 1) && (
                                                    <span>
                                                        <CancelRoundedIcon className="cp" onClick={(e) => extraDivisionLinkClose(i,item)} />
                                                    </span>
                                                )}
                                            </div>

                                        </Grid>
                                    </Grid>
                                </div>
                            )
                        })}
                        <div className={`row optionsStyle addButton ${classes.addIconButton} `}>
                            <AddCircleIcon className="addBtn" onClick={(e) => addDiVisionLink(e)} />
                        </div>
                        &nbsp;
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">Address</Typography>
                            <TextField
                                multiline
                                fullWidth
                                variant="outlined"
                                minRows={3}
                                placeholder="Enter Address"
                                value={ClientInfo.address}
                                onChange={handleAddressChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">Is Active*</Typography>
                            <Autocomplete
                                id="combo-box-demo"
                                value={ClientInfo.active}
                                options={activeDropDown}
                                disabled={editedRow ? isActiveFieldDisabled : false}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder="Select Active"
                                    />
                                )}
                                onChange={handleActiveChange}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <div className="dialog-button">
                        <button
                            className={isUserDataUpdating ? 'updateButton_OrgAdmin_disabled' : 'updateButton_OrgAdmin'}
                            onClick={handleSubmit}
                            disabled={isUserDataUpdating}
                        >
    
                            <span>Save</span>
                            {isUserDataUpdating ? (<CircularProgress className="circularProgress_updateButton"/>) : null}
                        </button>
                        <button onClick={handleCloseDialog} className="dialog-cancel-button">
                            Cancel
                        </button>
                    </div>
                </DialogActions>
            </Dialog>

        </>
    )
}

export default ManageClient;