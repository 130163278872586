import React, { useState, useEffect, useCallback, useRef } from "react";
import noProfilePictureImage from "./images/no-user.png";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import "date-fns";
import "react-dropdown/style.css";
import { NotificationManager } from "react-notifications";
import "./profileCompletion.css";
import Tooltip from "@material-ui/core/Tooltip";
import view from "./images/view.png";
import pdfIcon from "../../../pages/myTalentCommunity/icons/pdf.svg";
import { useHistory } from "react-router";
import { DateTime } from "luxon";
import { Backdrop, Button, Chip, CircularProgress, Dialog, DialogContent, DialogTitle, FormControl, FormHelperText, OutlinedInput, TextField, Typography } from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import cross from "../../../components/profile/profileCompletion/images/cross.svg"
import { FilePicker } from "react-file-picker";
import { BlobServiceClient } from "@azure/storage-blob";
import { Skeleton } from "@material-ui/lab";
import TimelineIcon from '@material-ui/icons/Timeline';
import pencil from '../../../components/profile/primarySkills/images/pencil.png';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import getAllPrimarySkillData from "../../../apis/getAllPrimarySkillData";
import CloseIcon from "@material-ui/icons/Close";
import PhoneInput, { isPossiblePhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import countryList from "../../../../src/utilities/country-state-city/lib/country.json";
import csc from "../../../utilities/country-state-city/dist";
import updateResume from "../../../apis/updateCandidateResumeById/updateCandidateResumeById";
import CancelTwoToneIcon from '@material-ui/icons/CancelTwoTone';
import countNoOfFilesParsed from "../../../apis/parsing/countNoOfFilesParsed";
const crypto = require('crypto'); //crypto module for hashing
const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: "#1A8FFF",
    },
    tooltip: {
        backgroundColor: "#1A8FFF",
        padding: "4px 13px",
        fontFamily: "Segoe-Semibold",
        fontSize: "13px",
    },
    buttonAlign: {
        display: "flex",
        alignItems: "center",
        padding: "24px 42px 24px 42px !important",
    },
}));


const useStyles = makeStyles((theme) => ({
    changeProfiletitle: {
        padding: "16px 42px!important",
    },
    changeprofilebody: {},
}))


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
    closeButton: {
        position: "absolute",
        right: "45px",
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    closeButtonForProfilePic: {
        position: "absolute",
        right: "8px",
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
}))(MuiDialogActions);

const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option.name,
});

const ProfileCompletion = ({
    candidateData,
    loadingController,
    profileCompletion,
    userId,
    userType,
    userRecruitmentPartnerId,
    backdropChangeHandler
}) => {
    const jsonConfig = require("../../../Config.json");
    const [candidateProfileData, setCandidateProfileData] = useState(candidateData);

    const [candidateProfileModalData, setCandidateProfileModalData] = useState({
        name: "",
        emailID: "",
        phoneNumber: "",
        countryCode: "",
        countryCallingCode: "",
        country: "",
        currency: "",
        state: "",
        city: "",
        primarySkill: "",
        additionalSkills: [],
        yearsOfExperience: "",
        currentCompensation: "",
        expectedCompensation: "",
        noticePeriod: "",
    });

    const [isUserdataUpdating, setisUserdataUpdating] = useState(false);
    const [profilePicture, setProfilePicture] = useState(noProfilePictureImage);
    const [isEmailCopied, setisEmailCopied] = useState(false);
    const history = useHistory();
    const [wrappedAdditionalSkills, setWrappedAdditionalSkills] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [modalIsOpen, setmodalIsOpen] = useState();
    const [primarySkill, setPrimarySkill] = useState([]);
    const [candidateResumeId, setCandidateResumeId] = useState(candidateProfileData?.candidateId
    );
    const [currencyList, setCurrencyList] = useState(countryList);
    const [additionalSkills, setAdditionalSkills] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [phoneError, setPhoneError] = useState(null);
    const [currentAdditionalValue, setCurrentAdditionalValue] = useState("");
    const [isPrimarySkillChange, setIsPrimarySkillChange] = useState(false);
    const [selectedFile, setSelectedFile] = useState({});
    const [progressOpen, setprogressOpen] = useState(false)
    const [progress, setProgress] = useState(0);
    const [notificationType, setNotifiactionType] = useState("");
    const [notificationText, setNotifcationText] = useState("")
    const uploadedFileParseCount = useRef(0);

    const fileExtension = (resumeUrl) => {
        let extension = resumeUrl.split(".").pop().toLowerCase();
        if (extension === "pdf") {
            return <img src={pdfIcon} alt="PDF Icon" className="fileImg_resume" />;
        } else if (extension === "doc" || extension === "docx") {
            return <img src={view} alt="DOC Icon" className="fileImg_resume" />;
        }
    };

    const fileExtensionArr = (oldProfiles) => {
        let extension = oldProfiles.split(".").pop().toLowerCase();
        if (extension === "pdf") {
            return (
                <img src={pdfIcon} alt="PDF Icon" className="fileImg" />
            );
        } else if (extension === "doc" || extension === "docx") {
            return (
                <img src={view} alt="DOC Icon" className="fileImg" />
            );
        }
    };

    const fileExtensionuploadResume = () => {
        if (selectedFile?.name) {
            let extension = selectedFile?.name.split('.').pop().toLowerCase();
            if (extension === 'pdf') {
                return <img src={pdfIcon} alt="PDF Icon" className="new_candidate_icon_profilecomplete" />
            } else if (extension === 'doc' || extension === 'docx') {
                return <img src={view} alt="DOC Icon" className="new_candidate_icon_profilecomplete" />
            }
        }


    }

    const openResume = (resumeUrl) => {
        window.open(resumeUrl, "_blank");
    };

    const getWrapItem = (item) => {
        let wordCount = item.split(" ");
        let word = "";
        if (wordCount.length > 5) {
            word =
                wordCount[0] +
                " " +
                wordCount[1] +
                " " +
                wordCount[2] +
                " " +
                wordCount[4] +
                " " +
                wordCount[5] +
                "...";
        } else word = item;
        return word;
    };

    const changeCopyText = () => {
        setisEmailCopied(false);
    };
    const copyEmail = (e, useremail) => {
        setisEmailCopied(true);
        navigator.clipboard.writeText(useremail);
        setTimeout(changeCopyText, 3000);
    };

    const getWrappedSecondarySkills = (skills) => {
        let arrData = [];
        skills?.map((item, i) => {
            if (i <= 15) {
                arrData.push(
                    <Tooltip title={item}>
                        <div className="chipStyle ">
                            <span className="chipstyle_skill_font">{getWrapItem(item)}</span>
                        </div>
                    </Tooltip>
                )
            }
        })
        return arrData;
    }

    const getAllSecondarySkills = (skills) => {
        let arrData = [];
        skills?.map((item, i) => {
            arrData.push(
                <Tooltip title={item}>
                    <div className="chipStyle ">
                        <span className="chipstyle_skill_font">{getWrapItem(item)}</span>
                    </div>
                </Tooltip>
            )
        })
        return arrData;
    }

    function BootstrapTooltip(props) {
        const classes = useStylesBootstrap();
        return <Tooltip arrow classes={classes} {...props} />;
    }

    const getWrappedEmail = (email) => {
        let wrappedemail = email.length > 28 ? email.substring(0, 28) + "..." : email;
        return (
            <BootstrapTooltip title={isEmailCopied ? "Copied" : "Click to copy Email"}>
                <span className="candidateDetails" onClick={(e) => copyEmail(e, email)}>{wrappedemail}</span>
            </BootstrapTooltip>
        );
    }

    const closeAddModal = () => {
        setmodalIsOpen(false);
        setIsPrimarySkillChange(false);
        setCandidateProfileModalData({
            name: "",
            emailID: "",
            phoneNumber: "",
            countryCode: "",
            countryCallingCode: "",
            country: "",
            currency: "",
            state: "",
            city: "",
            primarySkill: "",
            additionalSkills: [],
            yearsOfExperience: "",
            currentCompensation: "",
            expectedCompensation: "",
            noticePeriod: "",
        })
    }



    const openModal = () => {

        let candidateObj = {}
        candidateObj.name = candidateProfileData?.name;
        candidateObj.emailID = candidateProfileData?.emailID;
        candidateObj.countryCallingCode = candidateProfileData?.countryCallingCode;
        candidateObj.phoneNumber = candidateProfileData?.phoneNumber;

        let userCountry = countryList.filter((item) => item.name === candidateProfileData?.country)

        candidateObj.country = userCountry[0]
        candidateObj.countryCode = userCountry[0]?.isoCode

        if (candidateProfileData?.currency) {
            let currencydata = countryList.filter((item) => item.currency === candidateProfileData.currency)
            candidateObj.currency = currencydata[0];
        }
        else {
            candidateObj.currency = (countryList.filter((item) => item.phonecode === candidateProfileData?.countryCallingCode))[0]
        }

        let stateListDropdownData = csc.getStatesOfCountry(userCountry[0]?.isoCode);
        setStateList(stateListDropdownData);

        if (candidateProfileData?.state) {
            let parsedState = stateListDropdownData.filter((item) => item.name === candidateProfileData.state)
            candidateObj.state = parsedState[0];

            let cityListDropdownData = csc.getCitiesOfState(parsedState[0]?.countryCode, parsedState[0]?.isoCode);
            setCityList(cityListDropdownData);

            if (candidateProfileData?.city) {
                let parsedCity = cityListDropdownData.filter((item) => item.name === candidateProfileData.city)
                candidateObj.city = parsedCity[0];
            }
        }

        candidateObj.primarySkill = candidateProfileData?.primarySkill;
        candidateObj.additionalSkills = candidateProfileData?.skills;
        candidateObj.yearsOfExperience = candidateProfileData?.yr_of_exp;
        candidateObj.currentCompensation = candidateProfileData?.currentSalary;
        candidateObj.expectedCompensation = candidateProfileData?.expectedSalary;
        candidateObj.noticePeriod = candidateProfileData?.noticePeriod;

        setCandidateProfileModalData(candidateObj);
        setmodalIsOpen(true);
    };

    useEffect(() => {
        const fetchPrimarySkills = async () => {
            try {
                const response = await getAllPrimarySkillData();
                const skills = response.data.data.map(skill => skill.name);
                setPrimarySkill(skills);

            } catch (error) {
                console.error('Error fetching primary skills:', error);
            }
        };
        fetchPrimarySkills();
    }, []);

    const handlePrimarySkillsChange = (event, newValue) => {
        if (newValue) {
            setCandidateProfileModalData({
                ...candidateProfileModalData,
                primarySkill: newValue,
            })
        } else {
            setCandidateProfileModalData({
                ...candidateProfileModalData,
                primarySkill: "",
            })
        }
        setIsPrimarySkillChange(true);
    };


    const nameChangeHandler = (e) => {
        setCandidateProfileModalData({ ...candidateProfileModalData, name: e.target.value });
    };

    const yearOfExperienceHandler = (e) => {
        setCandidateProfileModalData({ ...candidateProfileModalData, yearsOfExperience: e.target.value });
    }

    const noticePeriodHandler = (e) => {
        setCandidateProfileModalData({ ...candidateProfileModalData, noticePeriod: e.target.value });
    }


    const currentCompensationHandler = (e) => {
        setCandidateProfileModalData({ ...candidateProfileModalData, currentCompensation: e.target.value });
    }

    const expectedCompensationHandler = (e) => {
        setCandidateProfileModalData({ ...candidateProfileModalData, expectedCompensation: e.target.value });
    }


    const currencyChangeHandler = (e, value) => {
        if (value != null) {
            setCandidateProfileModalData({ ...candidateProfileModalData, currency: value });
        }
    }


    const handleSkillsChange = (event, value) => {
        setCandidateProfileModalData({ ...candidateProfileModalData, additionalSkills: value });
        setAdditionalSkills([])

    };

    const additionSkillTextFeildChange = (e) => {
        setCurrentAdditionalValue(e.target.value)
        let arr = [];
        arr.push(e.target.value);
        setAdditionalSkills(arr);
    }

    const handlePhoneNumberChange = (val) => {
        if (val) {
            if (val && isPossiblePhoneNumber(val)) {
                let countryCode_PhoneNumber = `+${parsePhoneNumber(val).countryCallingCode}`
                setCandidateProfileModalData({
                    ...candidateProfileModalData,
                    countryCallingCode: countryCode_PhoneNumber,
                    phoneNumber: val.replace(countryCode_PhoneNumber, "")
                })
                setPhoneError(false);
            } else {
                setPhoneError(true);
            }
        }
        else {
            setPhoneError(false);
            setCandidateProfileModalData({
                ...candidateProfileModalData,
                countryCallingCode: '',
                phoneNumber: ""
            })
        }
    };

    const keypressHandler = (e) => {
        var invalidChars = ["-", "+", "e"];
        if (invalidChars.includes(e.key)) {
            e.preventDefault();
        }
    };

    const handleCountryChange = (event, value) => {
        if (!value) {
            setCandidateProfileModalData({
                ...candidateProfileModalData,
                country: "",
                state: "",
                city: "",
                countryCode: "",
            })
            setStateList([]);
        } else {
            setStateList(csc.getStatesOfCountry(value.isoCode));
            setCandidateProfileModalData({
                ...candidateProfileModalData,
                country: value,
                state: "",
                city: "",
                countryCode: value.isoCode,
            })
        }
        setCityList([]);
    };

    const handleStateChange = (e, value) => {
        if (value === null) {
            setCandidateProfileModalData({
                ...candidateProfileModalData,
                state: "",
                city: "",
            })
            setCityList([]);
        } else {
            setCandidateProfileModalData({
                ...candidateProfileModalData,
                state: value,
                city: "",
            })
            setCityList(csc.getCitiesOfState(value.countryCode, value.isoCode));
        }
    };

    const handleCityChange = (e, value) => {
        if (value === null) {
            setCandidateProfileModalData({
                ...candidateProfileModalData,
                city: "",
            })
        } else {
            setCandidateProfileModalData({
                ...candidateProfileModalData,
                city: value,
            })
        }
    };



    const updateCandidateData = async () => {
        if (phoneError === true) {
            NotificationManager.error(
                "Please enter a valid phone number",
                "Error",
                2500
            );
        }
        else if (candidateProfileModalData.name === "" ||
            candidateProfileModalData.phoneNumber === "" ||
            candidateProfileModalData.emailID === "" ||
            candidateProfileModalData.country === "" ||
            candidateProfileModalData.primarySkill === "") {
            NotificationManager.error(
                "Please fill in all the details",
                "Error",
                2500
            );
        } else {
            let additionalSkillArr = candidateProfileModalData.additionalSkills;
            if (isPrimarySkillChange && (candidateProfileData?.primarySkill !== candidateProfileModalData.primarySkill)) {
                let i = additionalSkillArr.indexOf(candidateProfileModalData.primarySkill);
                if (i !== -1) {
                    additionalSkillArr.splice(i, 1);
                }
                const index = additionalSkillArr.indexOf(candidateProfileData?.primarySkill);
                if (index !== -1) {
                    additionalSkillArr.splice(index, 1);
                }
                additionalSkillArr.unshift(candidateProfileData?.primarySkill);
            }

            setisUserdataUpdating(true)

            const payload = {
                emailID: candidateProfileModalData.emailID,
                phoneNumber: candidateProfileModalData.phoneNumber,
                name: candidateProfileModalData.name,
                primarySkill: candidateProfileModalData.primarySkill,
                skills: additionalSkillArr,
                city: candidateProfileModalData.city?.name,
                state: candidateProfileModalData.state?.name,
                country: candidateProfileModalData.country?.name,
                countryCode: candidateProfileModalData?.countryCode,
                countryCallingCode: candidateProfileModalData?.countryCallingCode,
                currency: candidateProfileModalData?.currency.currency,
                yr_of_exp: candidateProfileModalData?.yearsOfExperience,
                currentSalary: parseInt(candidateProfileModalData?.currentCompensation),
                expectedSalary: parseInt(candidateProfileModalData?.expectedCompensation),
                noticePeriod: parseInt(candidateProfileModalData?.noticePeriod),
                lastUpdatedBy: userId,
                recruitmentOrgId: userRecruitmentPartnerId,
            }
            try {
                const response = await updateResume(candidateResumeId, payload);
                if (response.status === 200) {
                    NotificationManager.success("Candidate Profile is successfully updated", "success");
                    let tempCandidateProfileData = { ...candidateProfileData };
                    tempCandidateProfileData.name = candidateProfileModalData.name;
                    tempCandidateProfileData.phoneNumber = candidateProfileModalData.phoneNumber;
                    tempCandidateProfileData.emailID = candidateProfileModalData.emailID;
                    tempCandidateProfileData.primarySkill = candidateProfileModalData.primarySkill;
                    tempCandidateProfileData.skills = candidateProfileModalData.additionalSkills;
                    tempCandidateProfileData.city = candidateProfileModalData?.city?.name;
                    tempCandidateProfileData.state = candidateProfileModalData?.state?.name;
                    tempCandidateProfileData.country = candidateProfileModalData?.country?.name;
                    tempCandidateProfileData.countryCode = candidateProfileModalData.countryCode;
                    tempCandidateProfileData.countryCallingCode = candidateProfileModalData.countryCallingCode;
                    tempCandidateProfileData.currency = candidateProfileModalData?.currency?.currency;
                    tempCandidateProfileData.yr_of_exp = candidateProfileModalData.yearsOfExperience;
                    tempCandidateProfileData.currentSalary = parseInt(candidateProfileModalData.currentCompensation);
                    tempCandidateProfileData.expectedSalary = parseInt(candidateProfileModalData.expectedCompensation);
                    tempCandidateProfileData.noticePeriod = parseInt(candidateProfileModalData.noticePeriod);
                    tempCandidateProfileData.lastUpdatedBy = userId;
                    tempCandidateProfileData.updated_at = response.data.updated;
                    setCandidateProfileData(tempCandidateProfileData);
                    closeAddModal();
                    setisUserdataUpdating(false)
                }
                else if (response.status === 202) {
                    NotificationManager.error(response.data.message, "Error");
                }

            } catch (error) {
                if (error?.response?.status === 400) {
                    const errorMessage = error.response.data.errMessage;
                    NotificationManager.error(errorMessage, "Error", 2500);
                }
                console.log(error);
            }
        }
    }

    const handleFileSelect = (file) => {
        const allowedExtensions = ['pdf', 'doc', 'docx'];
        const fileExtension = file.name.split('.').pop().toLowerCase();

        if (!allowedExtensions.includes(fileExtension)) {
            NotificationManager.error(
                "Only PDF and DOC files are allowed",
                "Error",
                2500
            );
        } else {
            setSelectedFile(file);
        }

    }

    const handleclose = () => {
        setSelectedFile("")
    }


    function create_UUID() {
        let dt = new Date().getTime();
        const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
            /[xy]/g,
            function (c) {
                const r = (dt + Math.random() * 16) % 16 | 0;
                dt >>>= 4; // unsigned right shift to ensure a 32-bit integer
                return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
            }
        );
        return uuid;
    }

    function hash_UUID(uuid) {
        const sha256 = crypto.createHash('sha256');
        sha256.update(uuid);
        return sha256.digest('hex');
    }


    const delayedFunction = async (filnameWithtimearr) => {

        const response = await countNoOfFilesParsed(filnameWithtimearr);

        if (response.status === 200) {

            uploadedFileParseCount.current = response.data.count;
        }
    }

    const uploadResumeToBlobStorage = async (blob) => {
        setprogressOpen(true)
        setmodalIsOpen(false)
        backdropChangeHandler(true)
        if (blob.length === 0) {
            setErrorMessage({ text: 'Select a file first.', visible: true });
            setTimeout(() => {
                setErrorMessage({ text: '', visible: false });
            }, 5000);
            return;
        }
        let filnameWithtimearr = []
        try {
            const timer = setInterval(() => {
                setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
            }, 800);

            const sas = decodeURI(jsonConfig.sasResumeToken);
            const date = DateTime.now();
            const currentDate = date.toFormat("yyyy-MM-dd");
            const formattedDate = date.toFormat("yyyy-MM-dd'T'HH:mm:ss.SSSZZ");

            const bsClient = new BlobServiceClient(
                `https://${jsonConfig.blobStorageResumeAccountName}.blob.core.windows.net${sas}`
            );

            const containerClient = bsClient.getContainerClient("staging");

            var v = blob.name.split(".");
            let uuid = create_UUID();
            let hashUuid = hash_UUID(uuid);
            let blobName = currentDate + "/" + userId + "/" + hashUuid + "." + v[v.length - 1];
            const blobClient = containerClient.getBlockBlobClient(blobName);

            const blobUploadOptions = {
                blobHTTPHeaders: {
                    blobContentType: blob.type,
                },
                metadata: {
                    "createdtime": formattedDate,
                    "fullname": blob.name,
                    "uploadedtype": "Recruiter Upload",
                    "organizationid": userRecruitmentPartnerId,
                    "blobfilename": blobName

                },
            };


            filnameWithtimearr.push({ "blobfilename": blobName, "createdtime": formattedDate, "organizationid": userRecruitmentPartnerId })


            const response = await blobClient.uploadBrowserData(blob, blobUploadOptions);

            const intervalID = setInterval(() => {
                if (stopCondition(filnameWithtimearr)) {
                    delayedFunction(filnameWithtimearr);
                } else {
                    clearInterval(intervalID);
                    setprogressOpen(false)
                    backdropChangeHandler(false)

                    window.location.reload();
                }
            }, filnameWithtimearr.length * 5000);

            setSelectedFile({})
        } catch (error) {
            setNotifiactionType('error')
            setNotifcationText('Resume upload failed')
            setSelectedFile({})
            console.error("An error occurred during upload:", error);
        }
    };

    const stopCondition = (filnameWithtimearr) => {
        if (uploadedFileParseCount.current === filnameWithtimearr.length) {
            return false
        } else {
            return true
        }
    }

    const goToCandidateHistory = () => {
        history.push({
            pathname: "/candidate-history",
            state: {
                candidateEmail: candidateProfileData.emailID,
                recruitmentOrgId: userRecruitmentPartnerId,
                checkCandidateHistory: true
            },

        });
    }

    return (
        <>
            {loadingController ? (
                <>
                    <div className="profileHeading">
                        <div className="candidateProfilecontainer_pencil">
                            <div className="poweResumeHeading_poweResumeRight">Candidate Profile</div>
                            <span className="pencilIcon_profilecompletion" ><img src={pencil} alt="" onClick={openModal} /></span>
                        </div>
                        <div className="poweResumeLastUpdated_poweResumeRight">
                            Last updated {candidateProfileData?.updated_at ? DateTime.fromISO(candidateProfileData?.updated_at).toFormat("dd-LLL-yyyy, hh:mm:ss a") : ''}
                        </div>
                    </div>
                    <div className="firstBlueContainer">

                        <div className="imgAndCVContainer">
                            {/* image */}
                            <div className="imageContainer">
                                <img
                                    src={profileCompletion?.profilePicUrl || profilePicture}
                                    alt=""
                                    className="only_candidate_profileImage_admin"
                                />

                            </div>
                            {/* resume  */}
                            {candidateProfileData?.resumeUrl ?
                                <div className="resumeContainer">
                                    <div className="color_04093f viewresume_container_profilecomplete" onClick={(e) => openResume(candidateProfileData.resumeUrl)}>{candidateProfileData?.resumeUrl ? fileExtension(candidateProfileData?.resumeUrl) : ""}
                                        <span className="viewText">View Resume</span>
                                    </div>
                                </div>
                                :
                                <div className="resumeContainer_noresume">
                                    {!selectedFile.name && (userType !== "Candidate") ?
                                        <>
                                            <FilePicker
                                                // extensions={['pdf', 'doc', 'docx']}
                                                onChange={(e) => handleFileSelect(e)}
                                            >
                                                <Button
                                                    className={userType === "Admin" ? "candidate_filepicker_button_disable" : "candidate_filepicker_button_profileCompleted_noresume"}
                                                    disabled={userType === "Admin"}
                                                >Choose Resume</Button>
                                            </FilePicker>

                                        </>
                                        : <>
                                            <div className="uploadFuctionContainer_conditional_profileComplete">
                                                <div className="uploadcontainer_profile_complete">

                                                    <div className="uploadFuctionCon_profileComplete">
                                                        <CancelTwoToneIcon onClick={(e) => handleclose()} className="close_icon_newCandidate" />
                                                        <div className="candidateFileExtention">{fileExtensionuploadResume()}</div>
                                                    </div>
                                                    <Button
                                                        className="candidate_filepicker_button_profileComplete"
                                                        onClick={(e) => uploadResumeToBlobStorage(selectedFile)}
                                                    >
                                                        upload Resume
                                                    </Button>

                                                </div>


                                            </div>
                                        </>}
                                </div>}
                        </div>



                        {/* candidate details */}
                        {Object.keys(candidateProfileData).length > 0 ?
                            <div className="detailsContainer">
                                <div className="partitionContainer">
                                    <div className="candidateName">{candidateProfileData?.name}</div>
                                    <div className="candidateDetails">{candidateProfileData?.emailID ?
                                        getWrappedEmail(candidateProfileData?.emailID)
                                        : ""}</div>
                                    <div className="candidateDetails">Ph: <span className="candidate_data_value">{candidateProfileData?.phoneNumber ? candidateProfileData?.countryCallingCode + " " + candidateProfileData?.phoneNumber : ""}</span> </div>
                                </div>
                                <div className="partitionContainer">
                                    {(candidateProfileData?.address != '' || candidateProfileData?.city != '' || candidateProfileData?.state != '' || candidateProfileData?.country != '') ? (<>
                                        <p className="candidateDetails">Address:<span className="candidate_data_value">{candidateProfileData?.address ? candidateProfileData?.address + ", " : ""}</span></p>
                                        {candidateProfileData?.city ? <p className="candidateDetails"><span className="candidate_data_value">{candidateProfileData?.city},</span></p> : ""}
                                        {candidateProfileData?.state ? <p className="candidateDetails"><span className="candidate_data_value">{candidateProfileData?.state},</span></p> : ""}
                                        {candidateProfileData?.country ? <p className="candidateDetails"><span className="candidate_data_value">{candidateProfileData?.country}</span></p> : ""}
                                    </>
                                    )
                                        : <p className="candidateDetails">Address: N/A</p>}
                                </div>
                                <div>
                                    <div className="candidateDetails">Upload: <span className="candidate_data_value">{candidateProfileData?.recruiterName ? candidateProfileData?.recruiterName : ""}</span></div>
                                    <div className="candidateDetails">Initial Upload: <span className="candidate_data_value">{candidateProfileData?.initialUploadDate ? DateTime.fromISO(candidateProfileData?.initialUploadDate).toFormat("dd-LLL-yyyy") : candidateProfileData?.created_at ? DateTime.fromISO(candidateProfileData?.created_at).toFormat("dd-LLL-yyyy") : ""}</span> </div>
                                    <div className="candidateDetails">Last Upload: <span className="candidate_data_value">{candidateProfileData?.lastUploadDate ? DateTime.fromISO(candidateProfileData?.lastUploadDate).toFormat("dd-LLL-yyyy") : candidateProfileData?.created_at ? DateTime.fromISO(candidateProfileData?.created_at).toFormat("dd-LLL-yyyy") : ""}</span> </div>
                                </div>
                            </div>
                            :
                            <div className="detailsContainer">
                                <div className="partitionContainer_skeleton">
                                    <Skeleton variant="rect" height={12} width={240} animation="wave" />
                                    <Skeleton variant="rect" height={12} width={240} animation="wave" />
                                    <Skeleton variant="rect" height={12} width={240} animation="wave" />
                                </div>
                                <div className="partitionContainer_skeleton">
                                    <Skeleton variant="rect" height={12} width={100} animation="wave" />
                                    <Skeleton variant="rect" height={12} width={240} animation="wave" />
                                    <Skeleton variant="rect" height={12} width={240} animation="wave" />
                                    <Skeleton variant="rect" height={12} width={240} animation="wave" />
                                </div>
                                <div className="partitionContainer_skeleton">
                                    <Skeleton variant="rect" height={12} width={240} animation="wave" />
                                    <Skeleton variant="rect" height={12} width={240} animation="wave" />
                                    <Skeleton variant="rect" height={12} width={240} animation="wave" />
                                </div>
                            </div>
                        }
                    </div>
                    {/* bottom cards */}
                    <div className="cardsContainer">
                        {candidateProfileData?.primarySkill ?
                            <div className="cardStyle">
                                <div className="primarySkillTitle_poweResumeRight">Primary Skill</div>
                                <div className="cardContent_primaryskill">
                                    <div className="PrimarySkillName_poweResumeRight">{candidateProfileData?.primarySkill ? candidateProfileData?.primarySkill : ''}</div>
                                    <div className="skillCardCaptions">Years of Experience: <span className="candidate_data_value">{candidateProfileData?.yr_of_exp ? candidateProfileData?.yr_of_exp : "N/A"}</span></div>
                                </div>
                            </div>
                            : null}
                        {candidateProfileData?.skills?.length > 0 ?
                            <div className="cardStyle">
                                <div className="primarySkillTitle_poweResumeRight">Secondary Skill</div>
                                <div className="chipContainer">
                                    {(candidateProfileData?.skills?.length > 15 && !wrappedAdditionalSkills) ? <>
                                        {getWrappedSecondarySkills(candidateProfileData?.skills)}
                                        <span className="morelesscount" onClick={(e) => setWrappedAdditionalSkills(true)}>...{candidateProfileData?.skills?.length - 15} more</span>
                                    </> : <>
                                        {getAllSecondarySkills(candidateProfileData?.skills)}
                                        {candidateProfileData?.skills?.length > 15 && wrappedAdditionalSkills ? <span className="morelesscount" onClick={(e) => setWrappedAdditionalSkills(false)}>...show less</span> : ""}
                                    </>}
                                </div>
                            </div>
                            : null}
                        {candidateProfileData?.currentSalary || candidateProfileData?.expectedSalary ?
                            <div className="cardStyle">
                                <div className="primarySkillTitle_poweResumeRight">Compensation</div>
                                <div className="cardContent">
                                    {candidateProfileData?.currentSalary ? <div className="skillCardCaptions">Current Compensation: <span className="candidate_data_value">{candidateProfileData?.currency} {candidateProfileData?.currentSalary}</span></div> : ""}
                                    {candidateProfileData?.expectedSalary ? <div className="skillCardCaptions">Expected Compensation: <span className="candidate_data_value">{candidateProfileData?.currency} {candidateProfileData?.expectedSalary}</span></div> : ""}
                                </div>
                            </div>
                            : null}
                        {candidateProfileData?.noticePeriod ?
                            <div className="cardStyle">
                                <div className="primarySkillTitle_poweResumeRight">Notice Period</div>
                                <div className="cardContent">
                                    <div className="skillCardCaptions">Notice Period: <span className="candidate_data_value">{candidateProfileData?.noticePeriod ? candidateProfileData?.noticePeriod : ""}</span></div>
                                </div>
                            </div>
                            : null}
                        {candidateProfileData?.oldProfiles?.length > 0 && candidateProfileData.oldProfiles[0] ?
                            <div className="cardStyle">
                                <p className="primarySkillTitle_poweResumeRight">Older Profile</p>
                                {candidateProfileData?.oldProfiles?.length > 0 ?
                                    <>
                                        {candidateProfileData?.oldProfiles?.length > 0 ?
                                            <div className="multiProfileBox_olderProfile viewresume_container_profile">
                                                <span className="skillCardCaptions">Profile 1: </span>
                                                <div className="viewresume_container_value" onClick={(e) => openResume(candidateProfileData?.oldProfiles[0])}>
                                                    {candidateProfileData?.oldProfiles?.length > 0 ? fileExtensionArr(candidateProfileData?.oldProfiles[0]) : ""}
                                                    <span className="skillCardCaptions">View Resume</span>
                                                </div>
                                            </div> : ""}
                                        {candidateProfileData?.oldProfiles?.length > 1 ?
                                            <div className="multiProfileBox_olderProfile viewresume_container_profile">
                                                <span className="skillCardCaptions">Profile 2: </span>
                                                <div className="viewresume_container_value" onClick={(e) => openResume(candidateProfileData?.oldProfiles[1])}>
                                                    {candidateProfileData?.oldProfiles?.length > 1 ? fileExtensionArr(candidateProfileData?.oldProfiles[1]) : ""}
                                                    <span className="skillCardCaptions">View Resume</span>
                                                </div>
                                            </div> : ""}
                                    </>
                                    :
                                    null}
                            </div>
                            : null}

                        {
                            userType === "Admin" ? <></>
                                :
                                <div className="candidateHistoryDiv">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={goToCandidateHistory}
                                    >
                                        <TimelineIcon />
                                        Candidate History
                                    </Button>
                                </div>
                        }
                    </div>
                    <Dialog
                        open={modalIsOpen}
                        onClose={closeAddModal}
                        aria-labelledby="customized-dialog-title"
                        maxWidth="sm"
                        fullWidth={true}
                    >
                        <DialogTitle className="dialogtitle_profilecompletion_dialog">
                            <div className="candidateProfile_text">Candidate Profile </div>
                            <div><CloseIcon onClick={closeAddModal} className="closeIconProfileComplete" /></div>
                        </DialogTitle>
                        <DialogContent>
                            <div className="dialogContainer_name">
                                <div className="inputLabel_right">Name*</div>
                                <FormControl fullWidth variant="outlined">
                                    <OutlinedInput
                                        placeholder="Name"
                                        className="admin_textbox"
                                        value={candidateProfileModalData?.name ? candidateProfileModalData?.name : ""}
                                        onChange={(e) => nameChangeHandler(e)}
                                    />
                                </FormControl>
                            </div>
                            <div className="dialogContainer_email mt-15">
                                <div className="inputLabel_right">Email*</div>
                                <FormControl fullWidth variant="outlined">
                                    <OutlinedInput
                                        placeholder="Email Address"
                                        className="admin_textbox"
                                        value={candidateProfileModalData?.emailID ? candidateProfileModalData?.emailID : ""}
                                        disabled
                                    />
                                </FormControl>
                            </div>
                            <div className="dialogContainer_phone mt-15">
                                <div className="inputLabel_right">Phone Number*</div>
                                <FormControl fullWidth variant="outlined">
                                    <PhoneInput
                                        international
                                        className="textbox_phone_input_pendingResume"
                                        placeholder="Enter Phone Number"
                                        value={candidateProfileModalData?.countryCallingCode + " " + candidateProfileModalData?.phoneNumber}
                                        onChange={(val) => handlePhoneNumberChange(val)}
                                        onKeyPress={(e) => keypressHandler(e)}
                                    />
                                    {phoneError && (
                                        <FormHelperText error>Please enter a valid phone number</FormHelperText>
                                    )}
                                </FormControl>
                            </div>
                            <div className="locationItemsContainer mt-15">
                                <div className="inputLabel_right">Address</div>
                                <div className="locationContainer_profilecomplete">
                                    <div className="locationitemcontainer_profilecomplete">
                                        <div className="inputLabel_right">Country*</div>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            className={candidateProfileModalData?.country !== "" ? "mt_14" : "admin_textbox_error"}
                                            options={countryList}
                                            autoHighlight
                                            filterOptions={filterOptions}
                                            getOptionLabel={(option) =>
                                                option.name
                                                    ? option.name
                                                    : ""
                                            }
                                            value={candidateProfileModalData?.country}
                                            onChange={handleCountryChange}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    required={true}
                                                    placeholder="Please Select Country"
                                                />}
                                        />
                                    </div>
                                    <div className="locationitemcontainer_profilecomplete">
                                        <div className="inputLabel_right">State</div>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            className="mt_14"
                                            options={stateList}
                                            autoHighlight
                                            filterOptions={filterOptions}
                                            getOptionLabel={(option) =>
                                                option.name ? option.name : ""
                                            }
                                            value={candidateProfileModalData?.state || ""}
                                            onChange={handleStateChange}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    required={true}
                                                    placeholder="Please Select State"
                                                />}
                                        />
                                    </div>
                                    <div className="locationitemcontainer_profilecomplete">
                                        <div className="inputLabel_right">City</div>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            className="mt_14"
                                            options={cityList}
                                            autoHighlight
                                            filterOptions={filterOptions}
                                            getOptionLabel={(option) =>
                                                option.name ? option.name : ""
                                            }
                                            value={candidateProfileModalData?.city || ""}
                                            onChange={handleCityChange}
                                            renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Please Select City" />}
                                        />
                                    </div>
                                </div>
                            </div>



                            <div className="dialogContainer mt-15">
                                <div className="inputLabel_right">Primary Skill*</div>
                                <Autocomplete
                                    id="multiple-limit-tags"
                                    getOptionLabel={(option) => option}
                                    options={primarySkill}
                                    value={candidateProfileModalData?.primarySkill || ""}
                                    freeSolo
                                    className="admin_textbox"
                                    onChange={handlePrimarySkillsChange}
                                    filterSelectedOptions
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            placeholder='Select Primary Skill'
                                            variant="outlined"
                                            fullWidth
                                        />
                                    }
                                />
                            </div>
                            <div className="dialogContainer mt-15">
                                <div className="inputLabel_right">Years of Experience</div>
                                <FormControl fullWidth variant="outlined">
                                    <OutlinedInput
                                        placeholder="Years of Experience"
                                        className="admin_textbox"
                                        type="number"
                                        inputProps={{ min: 0 }}
                                        value={candidateProfileModalData?.yearsOfExperience || ""}
                                        onChange={yearOfExperienceHandler}
                                    />
                                </FormControl>
                            </div>
                            <div className="dialogContainer mt-15">
                                <div className="inputLabel_right">Secondary Skill</div>
                                <Autocomplete
                                    multiple
                                    limitTags={4}
                                    id="multiple-limit-tags"
                                    options={additionalSkills}
                                    getOptionLabel={(option) => option}
                                    value={candidateProfileModalData?.additionalSkills || []}
                                    onChange={(event, value) => handleSkillsChange(event, value)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            value={currentAdditionalValue}
                                            onChange={(e) => additionSkillTextFeildChange(e)}
                                            placeholder="Select Additional Skills" />
                                    )}
                                />

                            </div>
                            <div className="itemContainer mt-15">
                                <div className="inputLabel_right">Compensation</div>
                                <div className="compensationcontainer">
                                    <div className="currentCompensation">
                                        <div className="inputLabel_right">Current Compensation</div>
                                        <div className="currency_CompensationContainer">
                                            <Autocomplete
                                                id="combo-box-demo"

                                                className='admin_textbox_currency_profileComplete'
                                                options={countryList}
                                                getOptionLabel={(option) =>
                                                    option?.name
                                                        ? option?.currency
                                                        : ""
                                                }
                                                value={candidateProfileModalData?.currency || ""}
                                                onChange={currencyChangeHandler}
                                                renderInput={(params) => <TextField {...params} placeholder="Currency" variant="outlined" />}
                                            />
                                            <FormControl fullWidth variant="outlined">
                                                <OutlinedInput
                                                    placeholder="Current Compensation"
                                                    className="currncy_compensationstyle2"
                                                    type="number"
                                                    inputProps={{ min: 0 }}
                                                    value={candidateProfileModalData?.currentCompensation || ""}
                                                    onChange={currentCompensationHandler}
                                                />
                                            </FormControl>
                                        </div>

                                    </div>
                                    <div className="expectedCompensation">
                                        <div className="inputLabel_right">Expected Compensation</div>
                                        <div className="currency_CompensationContainer">
                                            <Autocomplete
                                                id="combo-box-demo"
                                                className='currncy_compensationstyle1 '
                                                placeholder="Currency"
                                                options={currencyList}
                                                getOptionLabel={(option) =>
                                                    option?.name
                                                        ? option?.currency
                                                        : ""
                                                }
                                                value={candidateProfileModalData?.currency || ""}
                                                onChange={(e) => currencyChangeHandler(e)}
                                                renderInput={(params) => <TextField {...params} placeholder="Currency" variant="outlined" />}
                                            />
                                            <FormControl fullWidth variant="outlined">
                                                <OutlinedInput
                                                    placeholder="Expected Compensation"
                                                    className="currncy_compensationstyle2"
                                                    type="number"
                                                    inputProps={{ min: 0 }}
                                                    value={candidateProfileModalData?.expectedCompensation || ""}
                                                    onChange={expectedCompensationHandler}
                                                />
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="dialogContainer mt-15">
                                <div className="inputLabel_right">Notice Period(Days)</div>
                                <FormControl fullWidth variant="outlined">
                                    <OutlinedInput
                                        placeholder="Notice Period(Days)"
                                        className="admin_textbox"
                                        type="number"
                                        inputProps={{ min: 0 }}
                                        value={candidateProfileModalData?.noticePeriod || ""}
                                        onChange={noticePeriodHandler}
                                    />
                                </FormControl>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <button variant="outlined" color="primary" onClick={closeAddModal} className="cancelButton_profileComplete">
                                Cancel
                            </button>

                            <button
                                variant="contained"
                                color="primary"
                                className={isUserdataUpdating ? "saveButtonProfileComplete_disabled" : "saveButtonProfileComplete"}
                                disabled={isUserdataUpdating}
                                onClick={updateCandidateData}
                            >
                                <span>Save</span>
                                {isUserdataUpdating ? <CircularProgress /> : ""}
                            </button>
                        </DialogActions>
                    </Dialog>
                </>
            ) : (
                <></>
            )}
        </>
    );
};

export default ProfileCompletion;
